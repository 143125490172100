import React from 'react';
import { ButtonProps } from '@mui/material';

import { CustomButton } from '../Styles';
export interface ToolbarButtonProps extends ButtonProps {
  isDropdown?: boolean;
  size?: 'small' | 'medium' | 'large';
  pressed?: boolean;
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  children,
  isDropdown,
  size = 'medium',
  pressed,
  onClick,
}) => {
  return (
    <CustomButton
      variant="outlined"
      size={size}
      aria-checked={pressed}
      onClick={onClick}
      data-plate-focus
    >
      {children}
    </CustomButton>
  );
};
