import React from 'react';
import { withRef } from '@udecode/cn';
import { ToolbarButton } from './toolbar';
import { ListStyleType } from '@udecode/plate-indent-list';
import {
  useIndentListToolbarButton,
  useIndentListToolbarButtonState,
} from '@udecode/plate-indent-list/react';
import { StyledBulletedList, StyledNumberedList } from '../Styles';

export const IndentListToolbarButton = withRef<
  typeof ToolbarButton,
  {
    nodeType?: ListStyleType;
  }
>(({ nodeType = ListStyleType.Disc }, ref) => {
  const state = useIndentListToolbarButtonState({ nodeType });
  const { props } = useIndentListToolbarButton(state);
  const StyledIconComponent =
    nodeType === ListStyleType.Disc ? StyledBulletedList : StyledNumberedList;

  return (
    <ToolbarButton ref={ref} {...props}>
      <StyledIconComponent />
    </ToolbarButton>
  );
});
