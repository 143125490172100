import { forwardRef } from 'react';
import React from 'react';
import { PlateElementProps } from '@udecode/plate-common/react';
import { Typography } from '@octanner/prism-core';

interface HeadingElementProps extends PlateElementProps {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
}

export const HeadingElement = forwardRef<HTMLDivElement, HeadingElementProps>(
  ({ children, variant = 'h1', ...props }, ref) => {
    return <Typography variant={variant}>{children}</Typography>;
  },
);
