import { withProps } from '@udecode/cn';
import {
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { TableCellPlugin, TableRowPlugin } from '@udecode/plate-table/react';

import {
  ParagraphPlugin,
  PlateLeaf,
  usePlateEditor,
} from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { LinkPlugin } from '@udecode/plate-link/react';
import { HtmlReactPlugin } from '@udecode/plate-html/react';

import { editorPlugins } from './plugins/editor-plugins';
import { FixedToolbarPlugin } from './plugins/fixed-toolbar-plugin';
import { HeadingElement } from '../plate-ui/HeadingElement';
import { LinkElement } from '../plate-ui/LinkElement';
import { ParagraphElement } from '../plate-ui/ParagraphElement';
import { TableRowElement } from '../plate-ui/TableRowElement';
import { TableCellElement } from '../plate-ui/TableCellElement';
import { Types } from '../plate-types';
import { convertToNewPlateFormat } from '../editor/TextEditor';

export const useCreateEditor = (props: Types) => {
  const plugins = [...editorPlugins, HtmlReactPlugin, FixedToolbarPlugin];

  return usePlateEditor({
    override: {
      components: {
        [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
        [HEADING_KEYS.h1]: withProps(HeadingElement, { variant: 'h1' }),
        [HEADING_KEYS.h2]: withProps(HeadingElement, { variant: 'h2' }),
        [HEADING_KEYS.h3]: withProps(HeadingElement, { variant: 'h3' }),
        [HEADING_KEYS.h4]: withProps(HeadingElement, { variant: 'h4' }),
        [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'em' }),
        [LinkPlugin.key]: LinkElement,
        [ParagraphPlugin.key]: ParagraphElement,
        [TableRowPlugin.key]: TableRowElement,
        [TableCellPlugin.key]: TableCellElement,
        [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
      },
    },
    plugins,
    value: convertToNewPlateFormat(props.value || []),
  });
};
