import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal, Button } from '@octanner/prism-core';
import {
  useFloatingLinkUrlInput,
  useFloatingLinkUrlInputState,
} from '@udecode/plate-link/react';
import { useEffect, useRef, useState } from 'react';
import { ActionsContainer, FormContainer, StyledTextField } from '../Styles';

interface CustomModalProps {
  isOpen: boolean;
  modalRef?: React.Ref<HTMLDivElement>;
  textInputProps?: {
    defaultValue: string;
    ref: React.Ref<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement>;
  };
  label?: string;
}

export default function CustomModal({
  isOpen,
  modalRef,
  textInputProps,
  label,
}: CustomModalProps) {
  const { t } = useTranslation();
  const Linkstate = useFloatingLinkUrlInputState();
  const { props, ref: linkRef } = useFloatingLinkUrlInput(Linkstate);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isurlEmpty, setIsUrlEmpty] = useState(true);
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (inputRef.current) {
      const enterKeyEvent = new KeyboardEvent('keydown', {
        key: 'Enter',
        bubbles: true,
        cancelable: true,
      });
      inputRef.current.dispatchEvent(enterKeyEvent);
    }
  };

  useEffect(() => {
    setIsUrlEmpty(label !== t('prism-header:edit-link', 'Edit Link'));
    setOpen(isOpen);
  }, [isOpen, label]);

  const handleTextFieldChange = (event: any) => {
    if (props.onChange) {
      props.onChange(event);
    }
    setIsUrlEmpty(event.target.value === '');
  };

  return (
    <div>
      <Modal
        onMouseDownCapture={(e) => {
          if ((e.target as Element).closest('.MuiDialog-paper')) {
            e.stopPropagation();
          }
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="add-link-title"
        aria-describedby="add-link-description"
        id="link-modal"
        disableRestoreFocus
        title={label || ''}
        actions={
          <ActionsContainer ref={modalRef}>
            <Button
              disabled={isurlEmpty}
              variant="contained"
              onClick={handleClick}
            >
              {t('prism-header:save', 'Save')}
            </Button>
            <Button color="secondary" onClick={handleClose}>
              {t('prism-header:cancel', 'Cancel')}
            </Button>
          </ActionsContainer>
        }
      >
        <Box ref={linkRef}>
          <FormContainer ref={modalRef}>
            <StyledTextField label="Text" fullWidth {...textInputProps} />
            <StyledTextField
              inputRef={inputRef}
              label="Link"
              fullWidth
              onChange={handleTextFieldChange}
              defaultValue={props.defaultValue}
            />
          </FormContainer>
        </Box>
      </Modal>
    </div>
  );
}
