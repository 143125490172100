import { ParagraphPlugin } from '@udecode/plate-common/react';
import { HEADING_LEVELS } from '@udecode/plate-heading';
import {
  IndentListPlugin,
  IndentListConfig,
} from '@udecode/plate-indent-list/react';

export const indentListPlugins: IndentListConfig[] = [
  IndentListPlugin.extend({
    inject: {
      targetPlugins: [ParagraphPlugin.key, ...HEADING_LEVELS],
    },
    parsers: {
      html: {
        deserializer: {
          isElement: true,
          parse: ({ editor, element, getOptions }) => {
            return {
              type: editor.getType(ParagraphPlugin),
              indent: 1,
              listStyleType: element.parentElement?.style.listStyleType,
              listStart:
                Number(element.parentElement?.getAttribute('start')) || 1,
            };
          },
          rules: [
            {
              validNodeName: 'LI',
            },
          ],
        },
      },
    },
  }),
];
