import React, { useContext, useMemo } from 'react';
import { useFormInputProps } from '@udecode/plate-common/react';
import { useTranslation } from 'react-i18next';
import {
  type UseVirtualFloatingOptions,
  flip,
  offset,
} from '@udecode/plate-floating';
import {
  LinkOpenButton,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
} from '@udecode/plate-link/react';
import { Unlink } from 'lucide-react';
import LinkPopup from './LinkPopup';
import { Button, IconButton, Colors } from '@octanner/prism-core';
import { EditorContext } from '../context/EditorContext';
import { LinkFloatingToolbarProps } from '../plate-types';
import {
  VerticalSeparator,
  StyledExternalLink,
  LinkToolBarWrapper,
} from '../Styles';

const floatingOptions: UseVirtualFloatingOptions = {
  middleware: [
    offset(12),
    flip({
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
      padding: 12,
    }),
  ],
  placement: 'bottom-start',
};

export const LinkFloatingToolbar: React.FC<LinkFloatingToolbarProps> = ({
  state,
}) => {
  const { tannerBlack } = Colors;
  const { t } = useTranslation();
  const editorContext = useContext(EditorContext);
  const insertState = useFloatingLinkInsertState(
    useMemo(
      () => ({
        ...state,
        floatingOptions: {
          ...floatingOptions,
          ...state?.floatingOptions,
        },
      }),
      [state],
    ),
  );

  const {
    hidden,
    props: insertProps,
    ref: insertRef,
    textInputProps,
  } = useFloatingLinkInsert(insertState);

  const editState = useFloatingLinkEditState(
    useMemo(
      () => ({
        ...state,
        floatingOptions: {
          ...floatingOptions,
          ...state?.floatingOptions,
        },
      }),
      [state],
    ),
  );

  const {
    editButtonProps,
    props: editProps,
    ref: editRef,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState);

  const inputProps = useFormInputProps({
    preventDefaultOnEnterKeydown: true,
  });

  if (hidden) return null;

  const input = useMemo(
    () => (
      <LinkPopup
        modalRef={insertRef}
        textInputProps={textInputProps}
        {...inputProps}
        isOpen={insertState.isOpen && !editState.isOpen}
        label={t('prism-header:add-link', 'Add Link')}
      />
    ),
    [
      insertRef,
      textInputProps,
      inputProps,
      insertState.isOpen,
      editState.isOpen,
      state,
    ],
  );

  const editContent = useMemo(
    () =>
      editState.isEditing ? (
        <LinkPopup
          modalRef={editRef}
          textInputProps={textInputProps}
          {...editProps}
          isOpen={editState.isOpen}
          label={t('prism-header:edit-link', 'Edit Link')}
        />
      ) : (
        <LinkToolBarWrapper {...editProps} ref={editRef}>
          <Button
            color="inherit"
            size="small"
            variant="text"
            {...editButtonProps}
          >
            {t('prism-header:edit-link', 'Edit Link')}
          </Button>

          <VerticalSeparator orientation="vertical" />

          <IconButton size="small">
            <LinkOpenButton>
              <StyledExternalLink color={tannerBlack} width={18} />
            </LinkOpenButton>
          </IconButton>

          <VerticalSeparator orientation="vertical" />

          <IconButton size="small" {...unlinkButtonProps}>
            <Unlink width={18} />
          </IconButton>
        </LinkToolBarWrapper>
      ),
    [
      editState.isEditing,
      editRef,
      textInputProps,
      editProps,
      state,
      editButtonProps,
      unlinkButtonProps,
    ],
  );

  return (
    <>
      <div {...insertProps}>{input}</div>
      <div>{!editorContext?.readonly && editContent}</div>
    </>
  );
};
