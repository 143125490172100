import React from 'react';

import type { PlateElementProps } from '@udecode/plate-common/react';

import { Typography } from '@octanner/prism-core';

export const ParagraphElement = React.forwardRef<
  HTMLDivElement,
  PlateElementProps
>(({ children, className, ...props }, ref) => {
  return <Typography ref={ref}>{children}</Typography>;
});
