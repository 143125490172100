import React from 'react';

import { ButtonGroup } from '@octanner/prism-core';

export const ToolbarButtonGroup = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ButtonGroup variant="outlined" color="inherit">
      {children}
    </ButtonGroup>
  );
};
