import { indentListPlugins } from './indent-list-plugins';
import { autoformatPlugin } from './autoformat-plugin';
import { basicNodesPlugins } from './basic-nodes-plugins';
import { linkPlugin } from './link-plugin';
import { tablePlugin } from './table-plugin';
import { editorPluginConfig } from '../../plate-types';

export const editorPlugins: editorPluginConfig = [
  linkPlugin,
  tablePlugin,
  autoformatPlugin,
  ...basicNodesPlugins,
  ...indentListPlugins,
];
