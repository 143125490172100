import React from 'react';

import {
  PlateElement as PlateElementPrimitive,
  PlateElementProps,
} from '@udecode/plate-common/react';

export const PlateElement = React.forwardRef<HTMLDivElement, PlateElementProps>(
  ({ children, className, ...props }: PlateElementProps, ref) => {
    return (
      <PlateElementPrimitive ref={ref} {...props}>
        {children}
      </PlateElementPrimitive>
    );
  },
);
