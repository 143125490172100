import React, { createContext, useState, ReactNode } from 'react';

interface EditorContextProps {
  readonly: boolean;
  setReadOnly: (readonly: boolean) => void;
}

export const EditorContext = createContext<EditorContextProps>({
  readonly: false,
  setReadOnly: () => {},
});

export const EditorProvider = ({ children }: { children: ReactNode }) => {
  const [readonly, setReadOnly] = useState<boolean>(false);

  return (
    <EditorContext.Provider value={{ readonly, setReadOnly }}>
      {children}
    </EditorContext.Provider>
  );
};
