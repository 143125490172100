import { BasicMarksPlugin } from '@udecode/plate-basic-marks/react';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import { HeadingConfig } from '@udecode/plate-heading';
import { PluginConfig } from '@udecode/plate-core';
import { PlatePlugin } from '@udecode/plate-core/react';

export const basicNodesPlugins: readonly [
  HeadingConfig,
  PlatePlugin<PluginConfig<'basicMarks', {}, {}, {}>>,
] = [
  HeadingPlugin.configure({ options: { levels: 4 } }),
  BasicMarksPlugin,
] as const;
