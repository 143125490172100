import React from 'react';
import { MenuItem } from '@octanner/prism-core';
import {
  ParagraphPlugin,
  focusEditor,
  useEditorRef,
  useSelectionFragmentProp,
} from '@udecode/plate-common/react';
import { HeadingMenuItems } from '../constants';

import { getBlockType, setBlockType } from '../editor/transforms';
import { HeadingsDropdown } from '../Styles';
import { SelectChangeEvent } from '@mui/material';

export const BlockTypeDropdownMenu = () => {
  const editor = useEditorRef();

  const value = useSelectionFragmentProp({
    defaultValue: ParagraphPlugin.key,
    getProp: (node) => getBlockType(node),
  });
  const [selectedBlock, setSelectedBlock] = React.useState(value);

  const selectedItem = React.useMemo(() => {
    if (value) {
      const item =
        HeadingMenuItems.find((item) => item.value === value) ??
        HeadingMenuItems[0];
      setSelectedBlock(item.value);
      return item.value;
    }
    return selectedBlock;
  }, [value, HeadingMenuItems, selectedBlock]);
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setBlockType(editor, event.target.value as string);
    focusEditor(editor);
  };

  return (
    <HeadingsDropdown value={selectedItem} onChange={handleChange}>
      {HeadingMenuItems.map(({ label, value: itemValue }) => (
        <MenuItem key={itemValue} value={itemValue}>
          {label}
        </MenuItem>
      ))}
    </HeadingsDropdown>
  );
};
