import React from 'react';
import {
  FormatBold,
  FormatItalic,
  BulletedList,
  NumberedList,
  FormatUnderline,
  Link,
} from '@octanner/prism-icons';

import {
  BulletedListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { TablePlugin } from '@udecode/plate-table/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { ListStyleType } from '@udecode/plate-indent-list';
import { ParagraphPlugin } from '@udecode/plate-common/react';
import { AutoformatRule } from '@udecode/plate-autoformat';
import {
  BoldPlugin,
  CodePlugin,
  ItalicPlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { HeadingMenuItem } from './plate-types';

interface ToolbarContent {
  element: string;
  icon: JSX.Element | null;
  type: 'MARK' | 'BLOCK' | 'LIST' | 'LINK' | 'TABLE';
}

export const ToolbarButton: ToolbarContent[] = [
  {
    element: BoldPlugin.key,
    icon: <FormatBold />,
    type: 'MARK',
  },
  {
    element: ItalicPlugin.key,
    icon: <FormatItalic />,
    type: 'MARK',
  },
  {
    element: UnderlinePlugin.key,
    icon: <FormatUnderline />,
    type: 'MARK',
  },
  {
    element: BulletedListPlugin.key,
    icon: <BulletedList />,
    type: 'LIST',
  },
  {
    element: NumberedListPlugin.key,
    icon: <NumberedList />,
    type: 'LIST',
  },
  {
    element: LinkPlugin.key,
    icon: <Link />,
    type: 'LINK',
  },
  {
    element: TablePlugin.key,
    icon: null,
    type: 'TABLE',
  },
];

export const HeadingMenuItems: HeadingMenuItem[] = [
  {
    keywords: ['paragraph'],
    label: 'Paragraph',
    value: ParagraphPlugin.key,
  },
  {
    keywords: ['title', 'h1'],
    label: 'Heading 1',
    value: HEADING_KEYS.h1,
  },
  {
    keywords: ['subtitle', 'h2'],
    label: 'Heading 2',
    value: HEADING_KEYS.h2,
  },
  {
    keywords: ['subtitle', 'h3'],
    label: 'Heading 3',
    value: HEADING_KEYS.h3,
  },
  {
    keywords: ['subtitle', 'h4'],
    label: 'Heading 4',
    value: HEADING_KEYS.h4,
  },
  {
    keywords: ['unordered', 'ul', '-'],
    label: 'Bulleted list',
    value: ListStyleType.Disc,
  },
  {
    keywords: ['ordered', 'ol', '1'],
    label: 'Numbered list',
    value: ListStyleType.Decimal,
  },
];

export const autoformatMarks: AutoformatRule[] = [
  {
    match: '***',
    mode: 'mark',
    type: [BoldPlugin.key, ItalicPlugin.key],
  },
  {
    match: '__*',
    mode: 'mark',
    type: [UnderlinePlugin.key, ItalicPlugin.key],
  },
  {
    match: '__**',
    mode: 'mark',
    type: [UnderlinePlugin.key, BoldPlugin.key],
  },
  {
    match: '___***',
    mode: 'mark',
    type: [UnderlinePlugin.key, BoldPlugin.key, ItalicPlugin.key],
  },
  {
    match: '**',
    mode: 'mark',
    type: BoldPlugin.key,
  },
  {
    match: '__',
    mode: 'mark',
    type: UnderlinePlugin.key,
  },
  {
    match: '*',
    mode: 'mark',
    type: ItalicPlugin.key,
  },
  {
    match: '_',
    mode: 'mark',
    type: ItalicPlugin.key,
  },
  {
    match: '~~',
    mode: 'mark',
    type: StrikethroughPlugin.key,
  },
  {
    match: '^',
    mode: 'mark',
    type: SuperscriptPlugin.key,
  },
  {
    match: '~',
    mode: 'mark',
    type: SubscriptPlugin.key,
  },
  {
    match: '`',
    mode: 'mark',
    type: CodePlugin.key,
  },
];

export const autoformatBlocks: AutoformatRule[] = [
  {
    match: '# ',
    mode: 'block',
    type: HEADING_KEYS.h1,
  },
  {
    match: '## ',
    mode: 'block',
    type: HEADING_KEYS.h2,
  },
  {
    match: '### ',
    mode: 'block',
    type: HEADING_KEYS.h3,
  },
  {
    match: '#### ',
    mode: 'block',
    type: HEADING_KEYS.h4,
  },
  {
    match: '##### ',
    mode: 'block',
    type: HEADING_KEYS.h5,
  },
  {
    match: '###### ',
    mode: 'block',
    type: HEADING_KEYS.h6,
  },
];
