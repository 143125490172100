import React, { KeyboardEvent, useContext, useEffect } from 'react';
import type { PlateContentProps } from '@udecode/plate-common/react';

import { Types } from '../plate-types';
import { Tooltip } from '@octanner/prism-core';
import {
  StyledPlateContent,
  LockIconStyles,
  TextEditorWrapper,
  TooltipWrapper,
} from '../Styles';
import { EditorContext } from '../context/EditorContext';

export type EditorProps = PlateContentProps & Types;

export const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
  ({ className, disabled, ...props }, ref) => {
    const { disableEditor, id } = props;
    const { readonly, setReadOnly } = useContext(EditorContext);
    const readOnly = props.readOnly ?? readonly;

    useEffect(() => {
      setReadOnly(readOnly);
    }, [readOnly, setReadOnly]);

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (readOnly) {
        event.preventDefault();
      }
    };

    return (
      <TextEditorWrapper
        readOnly={readOnly}
        disableEditor={disableEditor ?? false}
        className={className}
      >
        {readOnly && (
          <TooltipWrapper>
            <Tooltip title="This field cannot be edited">
              <LockIconStyles viewBox="0 0 20 20" />
            </Tooltip>
          </TooltipWrapper>
        )}
        <StyledPlateContent
          id={id}
          ref={ref}
          spellCheck={false}
          readOnly={readOnly}
          disableEditor={disableEditor}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          contentEditable={!readOnly}
        />
      </TextEditorWrapper>
    );
  },
);

Editor.displayName = 'Editor';
