import React, { useState } from 'react';
import {
  ParagraphPlugin,
  PlateLeaf,
  createPlateEditor,
  Plate,
  TPlateEditor,
} from '@udecode/plate-common/react';
import { HtmlReactPlugin } from '@udecode/plate-html/react';
import { withProps } from '@udecode/cn';
import {
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { LinkPlugin } from '@udecode/plate-link/react';
import { TableRowPlugin } from '@udecode/plate-table/react';

import { HeadingElement } from '../plate-ui/HeadingElement';
import { LinkElement } from '../plate-ui/LinkElement';
import { ParagraphElement } from '../plate-ui/ParagraphElement';
import { TableRowElement } from '../plate-ui/TableRowElement';
import { basicNodesPlugins } from './plugins/basic-nodes-plugins';
import { indentListPlugins } from './plugins/indent-list-plugins';
import { MyValue } from '../plate-types';
import { EditorProvider } from '../context/EditorContext';
import { useCreateEditor } from './use-create-editor';
import { Editor } from './editor';
import { Types, EditorValue } from '../plate-types';
import { TDescendant, TElement, Value } from '@udecode/plate-common';

export const generatePlateHtml = (
  // ! Not in use? Potential bug!
  _value: MyValue,
  editor: TPlateEditor<Value>,
) => {
  const tmp = createPlateEditor({
    plugins: [
      HtmlReactPlugin,
      LinkPlugin,
      ...basicNodesPlugins,
      ...indentListPlugins,
    ],
    override: {
      components: {
        [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
        [HEADING_KEYS.h1]: withProps(HeadingElement, { variant: 'h1' }),
        [HEADING_KEYS.h2]: withProps(HeadingElement, { variant: 'h2' }),
        [HEADING_KEYS.h3]: withProps(HeadingElement, { variant: 'h3' }),
        [HEADING_KEYS.h4]: withProps(HeadingElement, { variant: 'h4' }),
        [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'em' }),
        [LinkPlugin.key]: LinkElement,
        [ParagraphPlugin.key]: ParagraphElement,
        [TableRowPlugin.key]: TableRowElement,
        [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
      },
    },
  });
  const html = tmp.api.htmlReact.serialize({ nodes: editor.children });
  // filtered out empty nodes generated by deserializeHtml when using custom paragraph plugin
  const filteredHtmlValue = html.replace(
    /<p>(<ol.*?>.*?<\/ol>|<ul.*?>.*?<\/ul>)<\/p>/g,
    '$1',
  );
  return filteredHtmlValue;
};
export const convertToNewPlateFormat = (jsonObj: MyValue) => {
  const plateValue: Value = [];
  if (typeof jsonObj !== 'object') {
    return jsonObj;
  }

  const mapChild = (child: TElement) => ({
    text: typeof child.text === 'string' ? child.text : '',
    bold: child.bold,
    italic: child.italic,
    underline: child.underline,
  });

  jsonObj.forEach((node) => {
    if (node.type === 'ul' || node.type === 'ol') {
      const listStyleType = node.type === 'ul' ? 'disc' : 'decimal';
      let listStart = 1;
      (node.children as TDescendant[]).forEach((li) => {
        (li.children as TDescendant[]).forEach((lic) => {
          const newChildren = (lic.children as TDescendant[]).flatMap(
            (child) => {
              if (child.type === 'a') {
                return {
                  type: 'a',
                  url: child.url,
                  children: (child.children as TElement[]).map(mapChild),
                };
              }
              return {
                text: typeof child.text === 'string' ? child.text : '',
                bold: child.bold,
                italic: child.italic,
                underline: child.underline,
              };
            },
          );
          plateValue.push({
            type: 'p',
            children: newChildren,
            indent: 1,
            listStyleType,
            ...(listStyleType === 'decimal' ? { listStart: listStart++ } : {}),
          });
        });
      });
    } else {
      plateValue.push(node as TElement);
    }
  });
  return plateValue;
};

const TextEditor = (props: Types) => {
  const editor = useCreateEditor(props);
  let debounceTimeoutId: NodeJS.Timeout | null = null;
  const {
    readOnly,
    disableEditor,
    enableToolbar,
    autoFocus,
    id,
    iconVisible,
    valueFormat,
    onUpdate,
  } = props;

  const plateValue =
    valueFormat === 'HTML' && typeof props.value === 'string'
      ? editor.api.html.deserialize({
          collapseWhiteSpace: false,
          element: props.value,
        })
      : props.value;

  const [value, setValue] = useState<MyValue>(() => {
    return convertToNewPlateFormat(plateValue || []);
  });

  const handleChange = ({
    editor,
    value,
  }: {
    editor: TPlateEditor<Value>;
    value: MyValue;
  }) => {
    setValue(value);
    if (typeof onUpdate === 'function') {
      debounceTimeoutId && clearTimeout(debounceTimeoutId);
      debounceTimeoutId = setTimeout(() => {
        const updateValue =
          valueFormat === 'HTML'
            ? generatePlateHtml(value, editor)
            : JSON.stringify(value);
        onUpdate(updateValue);
      }, 250);
    }
  };

  return (
    <EditorProvider>
      <Plate editor={editor} onChange={handleChange}>
        <Editor
          readOnly={readOnly}
          disableEditor={disableEditor}
          enableToolbar={enableToolbar}
          autoFocus={autoFocus}
          id={id}
          iconVisible={iconVisible}
          value={value as EditorValue}
          valueFormat={valueFormat}
          onUpdate={onUpdate}
        />
      </Plate>
    </EditorProvider>
  );
};

export default TextEditor;

TextEditor.defaultProps = {
  disableEditor: false,
  autoFocus: false,
  iconVisible: true,
  enableToolbar: true,
  valueFormat: 'JSON',
};
