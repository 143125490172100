import React from 'react';

import { createPlatePlugin } from '@udecode/plate-common/react';

import { FixedToolbarButtons } from '../../ToolBar/FixedToolbarButtons';

export const FixedToolbarPlugin: any = createPlatePlugin({
  render: {
    beforeEditable: () => <FixedToolbarButtons />,
  },
});
