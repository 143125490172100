import React from 'react';

import type { TLinkElement } from '@udecode/plate-link';

import { withRef } from '@udecode/cn';
import { useElement } from '@udecode/plate-common/react';
import { useLink } from '@udecode/plate-link/react';
import { StyledLink } from '../Styles';

export const LinkElement = withRef<any>(
  ({ children, className, ...props }, ref) => {
    const element = useElement<TLinkElement>();
    const { props: linkProps } = useLink({ element });

    return (
      <StyledLink ref={ref} as="a" {...(linkProps as any)} {...props}>
        {children}
      </StyledLink>
    );
  },
);
