import React, { useContext } from 'react';
import { ToolbarButton } from '../constants';
import { BulletedListPlugin } from '@udecode/plate-list/react';
import { IndentListToolbarButton } from '../plate-ui/IndentListToolbarButton';
import { MarkToolbarButton } from '../plate-ui/MarkToolbarButton';
import { BlockTypeDropdownMenu } from './BlockTypeDropdownMenu';
import { ToolbarButtonGroup } from '../plate-ui/ToolbarButtonGroup';
import { LinkPlugin } from '@udecode/plate-link/react';
import { ListStyleType } from '@udecode/plate-indent-list';
import { LinkToolbarButton } from '../plate-ui/LinkToolbarButton';
import { CustomToolbar, ToolbarIconStyles } from '../Styles';
import { EditorContext } from '../context/EditorContext';

export function FixedToolbarButtons(): JSX.Element | null {
  const editorContext = useContext(EditorContext);
  const DEFAULT_TAB_INDEX = 0;

  if (editorContext?.readonly) {
    return null;
  }

  return (
    <CustomToolbar>
      <ToolbarButtonGroup>
        <BlockTypeDropdownMenu />
      </ToolbarButtonGroup>

      <ToolbarButtonGroup>
        {ToolbarButton.map(({ element, icon, type }, index) => {
          if (type === 'MARK') {
            const tabIndex = index + 1;
            return (
              <MarkToolbarButton
                tabIndex={tabIndex}
                nodeType={element}
                key={element}
              >
                <ToolbarIconStyles>{icon}</ToolbarIconStyles>
              </MarkToolbarButton>
            );
          }
          return null;
        })}
      </ToolbarButtonGroup>

      <ToolbarButtonGroup>
        {ToolbarButton.map(({ element, icon, type }, index) => {
          if (type === 'LIST') {
            const tabIndex = index + 1;
            return (
              <IndentListToolbarButton
                tabIndex={tabIndex}
                nodeType={
                  element === BulletedListPlugin.key
                    ? ListStyleType.Disc
                    : ListStyleType.Decimal
                }
                key={element}
              >
                <ToolbarIconStyles>{icon}</ToolbarIconStyles>
              </IndentListToolbarButton>
            );
          }
          return null;
        })}
      </ToolbarButtonGroup>

      <ToolbarButtonGroup>
        <LinkToolbarButton tabIndex={DEFAULT_TAB_INDEX} key={LinkPlugin.key} />
      </ToolbarButtonGroup>
    </CustomToolbar>
  );
}
