import React from 'react';
import { withProps, withRef } from '@udecode/cn';
import {
  useTableCellElement,
  useTableCellElementState,
} from '@udecode/plate-table/react';
import { TableCellElementWrapper } from '../Styles';

import { PlateElement } from './PlateElement';

export const TableCellElement = withRef<
  typeof PlateElement,
  {
    hideBorder?: boolean;
    isHeader?: boolean;
  }
>(({ children, className, hideBorder, isHeader, style, ...props }, ref) => {
  const { rowSize } = useTableCellElementState();
  const { props: cellProps } = useTableCellElement({ element: props.element });

  return (
    <PlateElement
      ref={ref}
      as={isHeader ? 'th' : 'td'}
      {...cellProps}
      {...props}
    >
      <TableCellElementWrapper rowSize={rowSize ?? 0}>
        {children}
      </TableCellElementWrapper>
    </PlateElement>
  );
});

TableCellElement.displayName = 'TableCellElement';

export const TableCellHeaderElement = withProps(TableCellElement, {
  isHeader: true,
});
