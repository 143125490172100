import styled from '@emotion/styled';
import {
  Button,
  Box,
  Select,
  TextField,
  Divider,
  Colors,
  Link as LinkComponent,
} from '@octanner/prism-core';
import { PlateContent } from '@udecode/plate-common/react';
import { Link, Lock, BulletedList, NumberedList } from '@octanner/prism-icons';
import { ExternalLink } from 'lucide-react';
import { StyledPlateContentProps } from './plate-types';

const { tannerBlack, tannerGray, tannerBlue, tannerWhite } = Colors;

export const ToolbarButton: any = styled(Button)({
  padding: '0px',
  border: 'none',
  overflow: 'hidden',
  color: tannerGray[300],
  '&.Mui-focusVisible': {
    zIndex: 1,
  },
});

export const ButtonWrapper = styled(Box)({
  background: tannerWhite,
  borderColor: tannerGray[300],
  borderStyle: 'solid',
  borderWidth: '0px 0px 0px 0px',
  color: tannerGray[900],
  display: 'flex',
  flex: 'none',
  flexDirection: 'row',
  flexGrow: 0,
  gap: '4px',
  order: 0,
  boxSizing: 'border-box',
  alignItems: 'center',
});

export const HeadingsDropdown = styled(Select)({
  cursor: 'pointer',
  textAlign: 'start',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  gap: '8px',
  width: '160px',
  background: tannerWhite,
  border: `1px solid ${tannerGray[300]}`,
  borderRadius: '3px',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0,
  '& .MuiSelect-select': {
    padding: '0px 0px 0px 16px',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    height: '38px',
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: tannerWhite,
  },
  height: '40px',
  minHeight: '40px',
});
export const CustomToolbar = styled(Box)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '16px',
  background: tannerGray[50],
  borderWidth: '1px 1px 0px 1px',
  borderStyle: 'solid',
  borderColor: tannerGray[400],
  borderRadius: '3px 3px 0px 0px',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0,
  flexWrap: 'wrap',
  margin: '0px',
});

export const ToolbarIcon = styled.span({
  display: 'inline-block',
  verticalAlign: 'middle',
  paddingLeft: '6px',
  paddingTop: '7px',
});

export const StyledPlateContent = styled(PlateContent)<StyledPlateContentProps>(
  ({ readOnly, disableEditor }) => ({
    boxSizing: 'content-box',
    pointerEvents: readOnly ? 'none' : 'auto',
    opacity: disableEditor ? 0.4 : 1,
    height: '223px',
    overflowY: readOnly ? 'unset' : 'auto',
    padding: readOnly ? '0px 25px 0px 0px' : '16px',
    background: disableEditor ? tannerGray[50] : undefined,
    border: readOnly ? 'none' : `1px solid ${tannerGray[300]}`,
    borderRadius: '0px 0px 3px 3px',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    '&:focus-visible': {
      outline: 'none',
      border: `1px solid ${tannerGray[300]}`,
    },
    '& .slate-listStyleType-disc, .slate-listStyleType-decimal': {
      paddingLeft: '30px !important',
    },
    whiteSpace: 'pre-wrap',
  }),
);

export const LockIconStyles = styled(Lock)({
  position: 'absolute',
  top: '0px',
  right: '0px',
  color: tannerGray[400],
  pointerEvents: 'auto',
});

export const ToolbarIconStyles = styled.span({
  display: 'inline-block',
  verticalAlign: 'middle',
  paddingTop: '7px',
  paddingLeft: '6px',
});

export const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '20px 10px 20px 10px',
});

export const ActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '20px',
  padding: '16px 0px 16px 0px',
});

export const CustomButton: any = styled(Button)({
  padding: '0px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  background: tannerWhite,
  justifyContent: 'center',
  color: tannerBlack,
  border: `1px solid ${tannerGray[300]}`,
  '&:hover': {
    backgroundColor: tannerGray[100],
    border: `1px solid ${tannerGray[300]}`,
  },
  '&[aria-disabled="true"]': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  '&[aria-checked="true"]': {
    backgroundColor: tannerBlue[500],
    border: `1px solid ${tannerBlue[500]}`,
    color: tannerWhite,
  },
  '&.Mui-focusVisible': {
    zIndex: 1,
  },
});

export const TableCellElementWrapper = styled(Box)<{ rowSize: number }>(
  ({ rowSize }) => ({
    paddingTop: 10,
    paddingRight: 25,
    minHeight: rowSize,
  }),
);

export const StyledLinkIcon = styled(Link)({
  marginTop: '6px',
});

export const StyledTextField = styled(TextField)({
  marginTop: '5px',
});

export const StyledLink = styled(LinkComponent)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  lineHeight: 'inherit',
  fontSize: 'inherit',
});

const listIconStyles = {
  margin: '4px 0px 0px 4px',
};

export const StyledBulletedList = styled(BulletedList)(listIconStyles);

export const StyledNumberedList = styled(NumberedList)(listIconStyles);

export const LinkToolBarWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '2px',
  background: tannerWhite,
  borderRadius: '4px',
  boxShadow: `0px 3px 8px ${tannerBlack}33`,
  '.MuiButton-text': {
    padding: '5px',
    '&:hover': {
      backgroundColor: tannerGray[100],
    },
  },
});

export const StyledExternalLink = styled(ExternalLink)({
  marginTop: '3px',
});

export const VerticalSeparator = styled(Divider)({
  height: '24px',
  margin: '0 8px',
  alignSelf: 'center',
});

export const TextEditorWrapper = styled(Box)<{
  readOnly: boolean;
  disableEditor: boolean;
}>(({ readOnly, disableEditor }) => ({
  boxSizing: 'border-box',
  background: disableEditor ? tannerGray[50] : tannerWhite,
  border: readOnly ? `1px solid ${tannerGray[300]}` : 'none',
  padding: readOnly ? '16px' : '0px',
  borderRadius: '0px 0px 3px 3px',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 1,
  gap: '8px',
  zIndex: 0,
  overflow: 'auto',
  pointerEvents: 'auto',
  '&.MuiBox-root': {
    height: readOnly ? '300px' : 'auto',
  },
}));

export const TooltipWrapper = styled(Box)({
  position: 'relative',
});
